var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "timeslot-left-dialog",
      attrs: {
        title: "Внимание",
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("BillInnerModal", {
        attrs: { "type-bill": _vm.typeBill, "inner-visible": _vm.innerVisible },
        on: {
          close: function ($event) {
            _vm.innerVisible = false
          },
        },
      }),
      _c("span", [
        _vm._v(" На вашем балансе "),
        _c(
          "span",
          { staticStyle: { color: "#4ca7d8", "font-weight": "700" } },
          [_vm._v(" " + _vm._s(_vm.timeslotsLeft) + " ")]
        ),
        _vm._v(" таймслотов"),
        !_vm.addBalaceRequest
          ? _c("span", [
              _vm._v(" , для бронирования "),
              _c(
                "span",
                { staticStyle: { color: "#4ca7d8", "font-weight": "700" } },
                [_vm._v(" " + _vm._s(_vm.requestsCount) + " ")]
              ),
              _vm._v(" таймслотов необходимо оплатить еще "),
              _c(
                "span",
                { staticStyle: { color: "#f56c6c", "font-weight": "700" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.requestsCount - _vm.timeslotsLeft - 3) +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.addBalaceRequest
            ? _c(
                "iq-button",
                {
                  attrs: { "full-width": "", color: "second", size: "small" },
                  on: { onClick: _vm.close },
                },
                [_vm._v(" Изменить запрос ")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "timeslots-left__footer" },
            [
              _c(
                "iq-button",
                {
                  attrs: { "full-width": "", type: "primary", size: "small" },
                  on: { onClick: _vm.submitBillPay },
                },
                [_vm._v(" Cчет на оплату ")]
              ),
              _c(
                "iq-button",
                {
                  attrs: { "full-width": "", type: "primary", size: "small" },
                  on: { onClick: _vm.submitOnlinePay },
                },
                [_vm._v(" Пополнить онлайн ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }